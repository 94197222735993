<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title>Ofertas Disponíveis</v-toolbar-title>
                    <v-spacer/>
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text v-if="loading" class="text-center my-5">
                        <h1>Aguarde, carregando ofertas...</h1>
                    </v-card-text>
                    <v-card-text v-else class="text-center">
                        <div class="my-6" style="text-align: center; line-height: 30px;">
                            <h1>Economize R${{ totalSavings }} com o Plano Anual!</h1>
                        </div>

                        <div class="text-h5" style="text-align: center;">Essa oferta acaba em:</div>
                        <CountdownTimer :expireAt="offerExpiryDate" @expired="handleOfferExpiry" />

                        <v-row class="mt-4" justify="center">
                            <!-- <v-col cols="12" sm="6" md="4">
                                <v-card class="pa-4 monthly-card" style="height: 100%;">
                                    <v-card-title class="white--text justify-center">
                                        Plano Mensal
                                    </v-card-title>
                                    <v-card-text class="white--text">
                                        <p>
                                            <span class="text-body-1">Preço: R$ {{ currentPlanValue }} / mês</span>
                                        </p>
                                    </v-card-text>
                                </v-card>
                            </v-col> -->
                            <v-col cols="12" sm="6" md="4">
                                <v-card class="pa-4 highlighted-card annual-card" elevation="10">
                                    <div class="discount-banner">20% de desconto</div>

                                    <v-card-title class="white--text justify-center">
                                        Plano Anual
                                    </v-card-title>
                                    <v-card-text class="white--text text-body-1">
                                        <p>12 meses de acesso</p>
                                        <p>
                                            <b>Preço: R$ {{ annualValue }} / ano</b>
                                        </p>
                                        <br>
                                        <p>
                                            <b>Formas de pagamento:</b>
                                        </p>
                                        <p>
                                            <b>3x de R$ {{ installmentValue }} no cartão ou R$ {{ annualValue }} à vista no boleto/pix</b>
                                        </p>
                                        <br>
                                        <p>
                                            <i>Equivalente a R$ {{ equivalentValue }} / mês</i>
                                        </p>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn @click="tenhoInteresse" color="white" large block>
                                            <v-icon>mdi-whatsapp</v-icon>
                                            Tenho interesse
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>

                        <div class="mt-12 mb-8 mx-2 text-h5">Ficou interessado e gostaria de saber mais? Clique no botão abaixo e converse conosco pelo whatsapp 👇🏻</div>

                        <v-btn @click="queroSaberMais" color="success" x-large block>
                            <v-icon>mdi-whatsapp</v-icon>
                            Quero Saber Mais
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import CountdownTimer from './CountdownTimer';

export default {
    name: 'PlanoAnual',

    components: {
        BaseContainer,
        CountdownTimer,
    },

    data: () => ({
        loading: true,
        dados: [],
        offerExpiryDate: '2024-11-30T00:00:00'
    }),

    mounted() {
        this.consultar();
    },

    computed: {
        currentPlanValue() {
            const value = this.dados.value ? this.dados.value / 100 :  '';
            return this.formatPrice(value);
        },

        annualValue() {
            const value = this.dados.value ? this.dados.value / 100 * 12 * 0.8 :  '';
            return this.formatPrice(value);
        },

        equivalentValue() {
            const value = this.dados.value ? (this.dados.value / 100 * 12) / 12 * 0.8 :  '';
            return this.formatPrice(value);
        },

        totalSavings() {
            const value = this.dados.value ? this.dados.value / 100 * 12 * 0.2 :  '';
            return this.formatPrice(value);
        },

        installmentValue() {
            const value = this.dados.value ? (this.dados.value / 100 * 12 * 0.8) / 3 :  '';
            return this.formatPrice(value);
        },
    },

    methods: {
        consultar() {
            this.loading = true;
            this.dados = {};

            this.$http.post('assinaturas/detalhes').then(resp => {
                this.dados = resp.data.data;
                this.pageView();

                if (this.dados?.plan?.interval === 12) {
                    this.notify('Você ja possui o Plano Anual');
                    this.$router.push('/');
                }
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },

        pageView() {
            this.$http.post('logger?type=ofertas-disponiveis');
        },

        tenhoInteresse() {
            this.$http.post('logger?type=oferta-plano-anual-20off');

            const msg = encodeURIComponent('Olá, tenho interesse no Plano Anual com 20% de desconto');
            this.goToWhatsapp(msg);
        },

        queroSaberMais() {
            this.$http.post('logger?type=oferta-plano-anual-20off');

            const msg = encodeURIComponent('Olá, quero SABER MAIS sobre a oferta do Plano Anual com 20% de desconto');
            this.goToWhatsapp(msg);
        },

        goToWhatsapp(msg) {
            const isMobile = !this.$vuetify.breakpoint.smAndUp;

            const link = isMobile ? 'whatsapp://send' : 'https://api.whatsapp.com/send';
            const phone = '5549988951210';

            window.open(`${link}?phone=${phone}&text=${msg}`);
        },

        handleOfferExpiry() {
            //
        },
    },
}
</script>

<style scoped>
p {
    margin-bottom: 10px;
}

.highlighted-card:hover {
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
}

.monthly-card {
    background: linear-gradient(to right, #959da4, #bed1d2);
    border-radius: 10px;
    transform: scale(0.95);
}

.annual-card {
    /* background: linear-gradient(to right, #52bf54, #81d98f); */
    background: linear-gradient(to right, #000, #444444);
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s;
}

.discount-banner {
    position: absolute;
    top: 25px;
    left: -35px;
    background-color: #d21c1c;
    color: white;
    padding: 5px 20px;
    font-weight: bold;
    transform: rotate(-45deg);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
</style>