<template>
  <div class="countdown-timer">
    <div class="countdown-segment">
      <span class="countdown-number">{{ days }}</span>
      <span class="countdown-label">Dias</span>
    </div>
    <div class="countdown-segment">
      <span class="countdown-number">{{ hours }}</span>
      <span class="countdown-label">Horas</span>
    </div>
    <div class="countdown-segment">
      <span class="countdown-number">{{ minutes }}</span>
      <span class="countdown-label">Minutos</span>
    </div>
    <div class="countdown-segment">
      <span class="countdown-number">{{ seconds }}</span>
      <span class="countdown-label">Segundos</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CountdownTimer',

  props: {
    expireAt: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
      intervalId: null,
    };
  },
  methods: {
    calculateTime() {
      const expireDate = new Date(this.expireAt).getTime();
      const now = new Date().getTime();
      const timeLeft = expireDate - now;

      if (timeLeft <= 0) {
        clearInterval(this.intervalId);
        this.$emit('expired');
        return;
      }

      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

      this.days = this.formatTime(days);
      this.hours = this.formatTime(hours);
      this.minutes = this.formatTime(minutes);
      this.seconds = this.formatTime(seconds);
    },
    formatTime(time) {
      return time < 10 ? '0' + time : time.toString();
    }
  },

  mounted() {
    this.calculateTime();
    this.intervalId = setInterval(this.calculateTime, 1000);
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
.countdown-timer {
  display: flex;
  justify-content: center;
  gap: 10px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.5em;
  margin-bottom: 20px;
}

.countdown-segment {
  text-align: center;
  background: #fff;
  color: #222;
  padding: 10px 10px 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.countdown-segment::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.2), transparent);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
}

.countdown-number {
  display: block;
  font-size: 1.2em;
  font-weight: bold;
}

.countdown-label {
  font-size: 0.7em;
}
</style>